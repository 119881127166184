<template>
  <nav class="flex items-center justify-center" aria-label="Progress">
    <p class="text-sm font-medium">Schritt {{ currentStepIndex }} von {{ totalSteps }}</p>
    <ol role="list" class="ml-8 flex items-center space-x-5">
      <li v-for="step in steps" :key="step.name">
        <NuxtLink
          v-if="step.status === 'complete'"
          :to="step.href"
          class="block h-2.5 w-2.5 rounded-full bg-meevo-blue-500 hover:ring-2 hover:ring-meevo-blue-200 hover:ring-offset-1"
          :style="{ backgroundColor: color }"
        >
          <span class="sr-only">{{ step.name }}</span>
        </NuxtLink>
        <NuxtLink
          v-else-if="step.status === 'current'"
          :to="step.href"
          class="relative flex items-center justify-center"
          aria-current="step"
        >
          <span class="absolute flex h-5 w-5 p-px" aria-hidden="true">
            <span
              class="h-full w-full rounded-full bg-meevo-blue-200"
              :style="{ backgroundColor: changeHexColor(color || '', 40) }"
            ></span>
          </span>
          <span
            class="relative block h-2.5 w-2.5 rounded-full bg-meevo-blue-500"
            aria-hidden="true"
            :style="{ backgroundColor: color }"
          ></span>
          <span class="sr-only">{{ step.name }}</span>
        </NuxtLink>
        <NuxtLink v-else class="block h-2.5 w-2.5 rounded-full bg-gray-200">
          <span class="sr-only">{{ step.name }}</span>
        </NuxtLink>
      </li>
    </ol>
  </nav>
</template>
<script setup lang="ts">
const { steps, currentStepIndex, totalSteps } = useStep()
const { color } = useMarketplace()
</script>
